import React, { useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import beep from './assets/beep.mp3'
import { Link } from 'react-router-dom'

const SingleItem = () => {

    const formRef = useRef(null)
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbwXiEOUu3dxroiY3tL4PPYeFhDsgbaogM4Lcv7nf2exw54W-_1rLV_estOHup90ohcO/exec'

    const upcRef = useRef(null)
    const locationRef = useRef(null)
    const audioRef = useRef(null)

    const [items, setItems] = useState([])

    const [validUpc, setValidUpc] = useState(true)
    const [validLocation, setValidLocation] = useState(true)
    const setValidQuantity = useState(true)

    let validForm = true


    const [formData, setFormData] = useState({
        upc: '',
        location: '',
        quantity: '1'
    })

    const onTextChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        console.log(document.activeElement.name === 'quantity')
    }

    const validateForm = async (e) => {


        if (!formData.location.match(/\d{3}[-,]\w{1}\d{2}[-,]\w{1}\d{1}/)) {
            setValidLocation(false)
            locationRef.current.select()
            validForm = false
        }
        if (!formData.upc.match(/[a-zA-Z0-9-]{4}/) || formData.upc.match(/\d{3}[-,]\w{1}\d{2}[-,]\w{1}\d{1}/)) {
            setValidUpc(false)
            upcRef.current.select()
            validForm = false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setValidUpc(true)
        setValidLocation(true)
        setValidQuantity(true)

        validateForm()
        if (!validForm) {
            audioRef.current.play()
            return
        }
        console.log(formData)

        if (validForm) {
            fetch(scriptUrl, {
                method: 'POST',
                body: new FormData(formRef.current)

            }).then(res => {
                console.log('SUCCESSFULLY SUBMITTED')
            })
                .catch(err => console.log(err))
        }
        setItems([formData, ...items])
        setFormData({
            location: formData.location,
            upc: '',
            quantity: '1'
        })
        upcRef.current.focus()
    }



    return (
        <div className='container col-md-3 mt-5'>
            <div className='justify-content-center mb-2'>
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <Link className="nav-link active link-dark " to='/'>Scan Inventory</Link>
                    </li>
                </ul>
            </div>
            <div className='card card-body bg-light'>
                <form ref={formRef} onSubmit={handleSubmit} noValidate >
                    <div className='input-style'>
                        <div className='mb-3'>
                            <label><h5>Enter Location</h5></label>
                            <input className={validLocation ? 'form-control' : 'form-control is-invalid'} required type='text' placeholder='Location' autoFocus name='location' ref={locationRef} autoComplete='off' value={formData.location}
                                onChange={onTextChange} onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); upcRef.current.focus() } }} />
                            {!validLocation && <p className='text-danger fs-8'> <small>Please enter a valid Location</small></p>}
                        </div>
                        <div className='mb-3'>
                            <label><h5>Scan or Enter UPC</h5></label>
                            <input className={validUpc ? 'form-control' : 'form-control is-invalid'} required type='text' placeholder='UPC' name='upc' ref={upcRef} autoComplete='off' value={formData.upc}
                                onChange={onTextChange} />
                            {!validUpc && <p className='text-danger fs-8'> <small>Please enter a valid UPC</small></p>}
                        </div>
                        <input hidden readOnly value={formData.quantity} name='quantity'></input>
                        <audio hidden ref={audioRef}  >
                            <source src={beep} />
                        </audio>
                    </div>
                    <div className='input-style'>
                        <input type='submit' value='Submit' className='btn btn-primary mt-3' />
                    </div>
                </form>
            </div >
            {
                items.length > 0 &&
                <div className='card card-body bg-light mt-3'>
                    <h5>Scanned Items:</h5>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>UPC</th>
                                <th>Location</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>{items.map((i, k) =>
                            <tr key={k}>
                                <td>{i.upc}</td>
                                <td>{i.location}</td>
                                <td>{i.quantity}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            }
        </div >

    )
}

export default SingleItem