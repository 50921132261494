import React, { useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import beep from './assets/beep.mp3'
import { BsFillBackspaceFill } from "react-icons/bs"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import {Link} from 'react-router-dom'

const InventoryForm = () => {

    const formRef = useRef(null)
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbwXiEOUu3dxroiY3tL4PPYeFhDsgbaogM4Lcv7nf2exw54W-_1rLV_estOHup90ohcO/exec'

    const upcRef = useRef(null)
    const locationRef = useRef(null)
    const quantityRef = useRef(null)
    const audioRef = useRef(null)

    const [items, setItems] = useState([])

    const [validUpc, setValidUpc] = useState(true)
    const [validLocation, setValidLocation] = useState(true)
    const [validQuantity, setValidQuantity] = useState(true)

    let validForm = true


    const [formData, setFormData] = useState({
        upc: '',
        location: '',
        quantity: ''
    })

    const onNumClick = (e) => {
        setFormData({ ...formData, [e.target.name]: [formData.quantity] + e.target.value })
        console.log(document.activeElement.name === 'quantity')

    }

    const backspace = (e) => {
        setFormData({ ...formData, [e.target.name]: formData.quantity.substring(0, formData.quantity.length - 1) })
        console.log(formData.quantity.length)
    }

    const onTextChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        console.log(document.activeElement.name === 'quantity')
    }

    const validateForm = async (e) => {

        if (!formData.quantity.match(/^[0-9]+$/) || formData.quantity.match(/\d{3}[-,]\w{1}\d{2}[-,]\w{1}\d{1}/) ) {
            setValidQuantity(false)
            quantityRef.current.select()
            validForm = false
        }
        if (!formData.location.match(/\d{3}[-,]\w{1}\d{2}[-,]\w{1}\d{1}/)) {
            setValidLocation(false)
            locationRef.current.select()
            validForm = false
        }
        if (!formData.upc.match(/[a-zA-Z0-9-]{4}/) || formData.upc.match(/\d{3}[-,]\w{1}\d{2}[-,]\w{1}\d{1}/)) {
            setValidUpc(false)
            upcRef.current.select()
            validForm = false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setValidUpc(true)
        setValidLocation(true)
        setValidQuantity(true)

        validateForm()
        if (!validForm) {
            audioRef.current.play()
            return
        }

        if (validForm) {
            fetch(scriptUrl, {
                method: 'POST',
                body: new FormData(formRef.current)

            }).then(res => {
                console.log('SUCCESSFULLY SUBMITTED')
            })
                .catch(err => console.log(err))
            setItems([formData, ...items])
            setFormData({
                upc: '',
                location: '',
                quantity: ''
            })
            upcRef.current.focus()
        }
    }

    return (
        <div className='container col-md-3 mt-4'>
            <div className='justify-content-center mb-2'>
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <Link className="nav-link active link-dark " to='/singleitems'> Scan Single Items</Link>
                    </li>
                </ul>
                   </div>
            <div className='card card-body bg-light mt-6'>
                <form ref={formRef} onSubmit={handleSubmit} noValidate >
                    <div className='input-style'>
                        <div className='mb-3'>
                            <label><h5>Scan or Enter UPC</h5></label>
                            <input className={validUpc ? 'form-control' : 'form-control is-invalid'} required type='text' placeholder='UPC' name='upc' ref={upcRef} autoFocus autoComplete='off' value={formData.upc}
                                onChange={onTextChange} onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); locationRef.current.focus() } }} />
                            {!validUpc && <p className='text-danger fs-8'> <small>Please enter a valid UPC</small></p>}
                        </div>
                        <div className='mb-3'>
                            <label><h5>Enter Location</h5></label>
                            <input className={validLocation ? 'form-control' : 'form-control is-invalid'} required type='text' placeholder='Location' name='location' ref={locationRef} autoComplete='off' value={formData.location}
                                onChange={onTextChange} onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); quantityRef.current.focus() } }} />
                            {!validLocation && <p className='text-danger fs-8'> <small>Please enter a valid Location</small></p>}
                        </div>
                        <div className='mb-3'>
                            <label><h5>Enter Quantity</h5></label>
                            <div className="input-group ">

                                <input className={validQuantity ? 'form-control' : 'form-control is-invalid'} ref={quantityRef} required placeholder='Quantity' name='quantity' autoComplete='off' value={formData.quantity}
                                    onChange={onTextChange} />
                            </div>
                            {!validQuantity && <p className='text-danger fs-8'> <small>Please enter a valid Quantity</small></p>}
                            <div className='text-center col-md-9' onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); quantityRef.current.focus() } }}>
                                <div className="btn-group-vertical mt-2  justify-content-center" name='quantity' role="group" aria-label="Basic example">
                                    <div className="btn-group btn-group-large">
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="1" name='quantity' >1</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="2" name='quantity'>2</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="3" name='quantity'>3</button>
                                    </div>
                                    <div className='btn-group btn-group-large '>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="4" name='quantity'>4</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="5" name='quantity'>5</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="6" name='quantity'>6</button>
                                    </div>
                                    <div className='btn-group btn-group-large '>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="7" name='quantity'>7</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="8" name='quantity'>8</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="9" name='quantity'>9</button>
                                    </div>
                                    <div className='btn-group btn-group-large btn-group-justified'>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={backspace} value="7" name='quantity'><BsFillBackspaceFill size={10} /></button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={onNumClick} value="0" name='quantity' >0</button>
                                        <button type="button" className="btn btn-outline-secondary py-2 col-md-2" onClick={() => quantityRef.current.focus()} ><IoCheckmarkDoneSharp size={10} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <audio hidden ref={audioRef}  >
                                <source src={beep} />
                            </audio>
                        </div>
                    </div>
                    <div className='input-style'>
                        <input type='submit' value='Submit' className='btn btn-primary mt-3' />
                    </div>
                </form>
            </div>
            {items.length > 0 &&
                <div className='card card-body bg-light mt-3'>
                    <h5>Scanned Items:</h5>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>UPC</th>
                                <th>Location</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>{items.map((i, k) =>
                            <tr key={k}>
                                <td>{i.upc}</td>
                                <td>{i.location}</td>
                                <td>{i.quantity}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>}
        </div>

    )
}

export default InventoryForm

