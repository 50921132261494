import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import InventoryForm from './Main';
import SingleItem from './SingleItem';

export default class App extends Component {

    render() {
        return (
            <Routes>
                <Route path='/' element={<InventoryForm />} />
                <Route path='/singleitems' element={<SingleItem />} />
            </Routes>
        );
    }
}